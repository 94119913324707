import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './style/global.css';
import ErrorPage from './components/error-page';
import About from './components/About';
import Applications from './components/Applications';
import Deployment from './components/Deployment';
import AiMl from './components/AIML';
import Offer from './components/Offer';
import ReactGA from 'react-ga4';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/fields/applications",
    element: <Applications />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/fields/deployment",
    element: <Deployment />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/fields/aiml",
    element: <AiMl />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/offer",
    element: <Offer />,
    errorElement: <ErrorPage />,
  },
  // Redirects
  {
    path: "/big-data",
    loader: async () => redirect("/fields/aiml"),
  },
  {
    path: "/mobile",
    loader: async () => redirect("/fields/applications"),
  },
  {
    path: "/consulting",
    loader: async () => redirect("/"),
  },
  {
    path: "/development",
    loader: async () => redirect("/fields/applications"),
  }
]);

ReactGA.initialize('G-81YMNGJKFF');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);